<template>
  <b-card>
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Information</span>
        </template>

        <ShowInfo />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="TruckIcon" />
          <span>Colis</span>
        </template>

        <colis :supplier="this.$route.params.id" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="DollarSignIcon" />
          <span>Facture</span>
        </template>

        <b-card-text>
          <facture :supplier="this.$route.params.id" />
        </b-card-text>
      </b-tab>

    </b-tabs>
  </b-card>

</template>

<script>
import {
  BTabs, BTab, BCardText, BCard,
} from 'bootstrap-vue'
import ShowInfo from './ShowInfo.vue'
import colis from '../orders/Index.vue'
import facture from '../invoices/Index.vue'

export default {
  components: {
    BCardText,
    BTabs,
    BTab,
    BCard,
    ShowInfo,
    colis,
    facture,
  },

}
</script>
