<template>
  <div>
    <!-- input Recherche -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group>
        <b-form-group
          v-if="user.role === 'admin'"
          label="Suivi des dépôts"
        >
          <validation-provider
            #default="{ errors }"
            name="Depot"
          >
            <v-select
              v-model="repository"
              :clearable="false"
              placeholder="Depots"
              label="name"
              :options="repositories"
              @input="getOrders(1)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <div class="d-flex align-items-center mt-3">
          <label class="mr-1 h3">Etat de colis</label>
          <b-form-radio-group
            v-model="status"
            @input="getOrders(1)"
          >
            <b-form-radio
              value="0"
              class="h3"
            >
              En attente
            </b-form-radio>
            <b-form-radio
              value="1"
              class="h3"
            >
              En pickup
            </b-form-radio>
            <b-form-radio
              value="15"
              class="h3"
            >
              Anomalie de pickup
            </b-form-radio>
            <b-form-radio
              value="2"
              class="h3"
            >
              au dépôt
            </b-form-radio>
            <b-form-radio
              value="3"
              class="h3"
            >
              En cours
            </b-form-radio>
            <b-form-radio
              value="4"
              class="h3"
            >
              Livré
            </b-form-radio>
            <b-form-radio
              value="6"
              class="h3"
            >
              Livré payé
            </b-form-radio>
            <b-form-radio
              value="8"
              class="h3"
            >
              Transfert
            </b-form-radio>
            <b-form-radio
              value="5"
              class="h3"
            >
              Retour Au dépôt
            </b-form-radio>

            <b-form-radio
              value="7"
              class="h3"
            >
              Retour Au fournisseur
            </b-form-radio>
            <b-form-radio
              value="9"
              class="h3"
            >
              Retour payé
            </b-form-radio>
            <b-form-radio
              value="12"
              class="h3"
            >
              Anomalie de livraison
            </b-form-radio>
            <b-form-radio
              value="13"
              class="h3"
            >
              Échange
            </b-form-radio>
            <b-form-radio
              value="10"
              class="h3"
            >
              Retour inter-agence
            </b-form-radio>
            <b-form-radio
              value="11"
              class="h3"
            >
              Retour inter-agence reçu
            </b-form-radio>
            <b-form-radio
              :value="null"
              class="h3"
            >
              Tous les colis
            </b-form-radio>
          </b-form-radio-group>
        </div>
      </b-form-group>

    </div>
    <b-row>
      <b-col md="2">
        <b-form-group
          label="bl"
          label-for="bl"
        >
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="bl"
              v-debounce:1000="findById"
              placeholder="Recherche"
              type="number"
              class="d-inline-block"
              @keypress="isNumber"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="Nom"
          label-for="Name"
        >
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="name"
              v-debounce:1000="findByName"
              placeholder="Entrer  le nom du client"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="Téléphone"
          label-for="Téléphone"
        >
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="phone"
              v-debounce:1000="findByPhone"
              placeholder="Téléphone"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col
        md="3"
        class="d-flex align-items-center justify-content-between"
      >
        <b-form-group
          label="Gouvernoart"
          label-for="gouvernoart"
          class="flex-grow-1"
        >
          <v-select
            v-model="governorate"

            :clearable="false"
            placeholder="gouvernorats"
            label="name"
            :options="governorates"
            @input="findByGovernorate()"
          />
        </b-form-group>
        <feather-icon
          id="governorat"
          class="mt-1 ml-1"
          variant="primary"
          icon="XSquareIcon"
          size="20"
          @click="clearGovernorate()"
        />

        <b-tooltip
          title="Annuler filtrage par gouvernorat"
          class="cursor-pointer"
          target="governorat"
        />

      </b-col>
      <b-col
        v-if="user.role === 'admin' || user.role === 'manager'"
        md="3"
        class="d-flex align-items-center justify-content-between"
      >
        <b-form-group
          label="Fournisseur"
          label-for="Fournisseur"
          class="flex-grow-1"
        >
          <v-select
            v-model="supplier1"
            :clearable="false"
            placeholder="fournisseur"
            label="username"
            :options="suppliers"
            @input="findBySupplier()"
          />
        </b-form-group>
        <feather-icon
          id="supplier"
          class="mt-1 ml-1"
          variant="primary"
          icon="XSquareIcon"
          size="20"
          @click="clearSupplier()"
        />

        <b-tooltip
          title="Annuler filtrage par fournisseur"
          class="cursor-pointer"
          target="supplier"
        />

      </b-col>

      <!-- <div class="d-flex align-items-center mb-1 mr-1">
        <label class="mr-1">Date</label>

        <flat-pickr
          v-model="date"
          class="form-control invoice-edit-input"
          @input="getOrders()"
        />
      </div> -->
      <b-col
        md="2"
        class="mt-1"
      >
        <b-form-group v-if="user.role === 'supplier'">
          <b-button
            :to="{ name: 'order-add' }"
            variant="gradient-primary"
            class="ml-1 d-inline-block"
          >
            <feather-icon icon="PlusIcon" />
            <span class="ml-1">Ajouter colis</span>
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="orders"
      :current-page="currentPage"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div
          v-if="props.column.field === 'id'"
          class="text-nowrap"
        >
          <qrcode-vue
            style="display:inline"
            :value="props.row.id.toString()"
            :size="size"
            level="H"
          />

        </div>
        <span v-if="props.column.field === 'status'">

          <div v-if="props.row.status === 0">
            <b-badge
              class="badge-glow bg-warning bg-lighten-1"
              pill
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span> En attente</span>
            </b-badge>
          </div>
          <div v-if="props.row.status === 1">
            <b-badge
              class="badge-glow bg-warning bg-darken-2"
              pill
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span> En Pickup</span>
            </b-badge>
          </div>
          <div v-if="props.row.status === 2">
            <b-badge
              class="badge-glow bg-info bg-lighten-1"
              pill
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span> Au dépôt</span>
            </b-badge>

          </div>
          <div v-if="props.row.status === 3">
            <b-badge
              class="badge-glow bg-info bg-darken-2"
              pill
              variant="success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span> En cours</span>
            </b-badge>
          </div>

          <div v-if="props.row.status === 4">
            <b-badge
              class="badge-glow bg-success bg-lighten-2"
              pill
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span> Livré </span>
            </b-badge>
          </div>
          <div v-if="props.row.status === 5">
            <b-badge
              class="badge-glow bg-danger bg-lighten-2"
              pill
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span> Retour Au dépôt </span>
            </b-badge>
          </div>
          <div v-if="props.row.status === 12">
            <b-badge
              class="badge-glow bg-danger bg-lighten-2"
              pill
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span> Anomalie de livraison </span>
            </b-badge>
          </div>
          <div v-if="props.row.status === 15">
            <b-badge
              class="badge-glow bg-danger bg-lighten-2"
              pill
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span> Anomalie de pickup </span>
            </b-badge>
          </div>
          <div v-if="props.row.status === 13">
            <b-badge
              class="badge-glow bg-success bg-lighten-2"
              pill
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span> Échange </span>
            </b-badge>
          </div>
          <div v-if="props.row.status === 8">
            <b-badge
              class="badge-glow bg-secondary bg-lighten-2"
              pill
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span> Transfert </span>
            </b-badge>
          </div>
          <div
            v-if="props.row.status === 7"
          >
            <b-badge
              class="
            badge-glow"
              pill
              variant="danger"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span> Retour au fournisseur </span>
            </b-badge>
          </div>
          <div
            v-if="props.row.status === 6"
          >
            <b-badge
              class="
            badge-glow"
              pill
              variant="success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span> Livré payé </span>
            </b-badge>
          </div>
          <div
            v-if="props.row.status === 9"
          >
            <b-badge
              class="
            badge-glow"
              pill
              variant="danger"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span> Retour payé </span>
            </b-badge>
          </div>
          <div
            v-if="props.row.status === 10"
          >
            <b-badge
              class="
            badge-glow"
              pill
              variant="danger"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>Retour inter-agence</span>
            </b-badge>
          </div>
          <div
            v-if="props.row.status === 11"
          >
            <b-badge
              class="
            badge-glow"
              pill
              variant="danger"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>Retour inter-agence reçu</span>
            </b-badge>
          </div>
        </span>
        <span v-else-if="props.column.field === 'phone1'">
          {{ props.row.phone1 }}
          <span v-if="props.row.phone2">-</span>
          {{ props.row.phone2 }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>

            <router-link
              class="text-success"
              :to="{ name: 'delivery-note-show',
                     params: { id: props.row.id} }"
            > <feather-icon
              v-if="((props.row.status === 0 || props.row.status === 1 ) && user.role === 'supplier')"
              :id="`invoice-row-${props.row.id}-showdel-icon`"
              icon="PrinterIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <router-link
              class="text-success"
              :to="{ name: 'delivery-note-show',
                     params: { id: props.row.id} }"
            > <feather-icon
              v-if="user.role === 'admin' || user.role === 'manager'"
              :id="`invoice-row-${props.row.id}-showdel-icon`"
              icon="PrinterIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Bon de livraison"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-showdel-icon`"
            />

            <router-link
              class="text-info"
              :to="{ name: 'order-show',
                     params: { id: props.row.id} }"
            > <feather-icon
              :id="`invoice-row-${props.row.id}-show-icon`"
              icon="EyeIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Détail"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-show-icon`"
            />

            <router-link
              class="text-warining"
              :to="{ name: 'order-edit',
                     params: { id: props.row.id} }"
            >  <feather-icon
              v-if="(props.row.status === 0 || props.row.status === 1) && (user.role === 'supplier')"
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <router-link
              class="text-warning"
              :to="{ name: 'order-edit',
                     params: { id: props.row.id} }"
            >  <feather-icon
              v-if="(user.role === 'admin')"
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

            <feather-icon
              v-if="(user.role ==='admin' && (props.row.status === 0 || props.row.status === 1))"
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="cursor-pointer text-danger mr-1"
              size="16"
              @click="deleteOrder(props.row.id)"
            />
            <b-tooltip
              v-if="(user.role ==='admin' && (props.row.status === 0 || props.row.status === 1))"
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

            <feather-icon
              v-if="((user.role ==='manager'|| user.role ==='supplier') && (props.row.status === 0 || props.row.status === 1))"
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="cursor-pointer text-danger mr-1"
              size="16"
              @click="deleteOrder(props.row.id)"
            />
            <b-tooltip
              v-if="((user.role ==='manager'|| user.role ==='supplier') && (props.row.status === 0 || props.row.status === 1))"
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />
            <feather-icon
              v-if="props.row.return_number >= 1"
              :id="`invoice-row-${props.row.id}-hist-icon`"
              icon="InfoIcon"
              class="cursor-pointer text-danger"
              size="16"
              @click="openReturnReasonModal(props.row.id)"
            />
            <b-tooltip
              v-if="props.row.return_number >= 1"
              title="historique de retour"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-hist-icon`"
            />
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Affichage 1 à            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> de {{ count }} entrées
            </span>
          </div>

          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="count"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="pageNext(currentPage)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
          <b-modal
            ref="returnReasonModal"
            title="historique de retour"
          >
            <ul
              v-for="reason in returnDetails"
              :key="reason.content"
            >
              <li>{{ reason.motif_retour }} - {{ reason.created_at }}</li>
            </ul>
          </b-modal>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BButton, BTooltip, BBadge, BFormRadio, BFormRadioGroup, BSpinner, BFormSelect, BRow, BCol, BModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import storeAuth from '@/store/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import QrcodeVue from 'qrcode.vue'
import formatDistance from 'date-fns/formatDistance'
import { fr } from 'date-fns/locale'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BTooltip,
    BButton,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
    vSelect,
    ValidationProvider,
    BSpinner,
    BFormSelect,
    QrcodeVue,
    BRow,
    BCol,
    BModal,
  },
  props: {
    supplier: {
      type: String,
      default: () => null,
    },
    level: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      size: 80,
      date: null,
      status: 0,
      pageLength: 30,
      rangeDate: null,
      dir: false,
      currentPage: 1,
      count: 0,
      name: '',
      phone: '',
      governorates: [],
      governorate: '',
      columns: [
        {
          label: 'Action',
          field: 'action',
          width: '90px',
        },
        {
          label: 'BL',
          field: 'id',
          width: '130px',
        },
        {
          label: 'Client',
          field: 'name',
        },
        {
          label: 'Fournisseur',
          field: 'supplier.username',
        },
        {
          label: 'Gouvernorat',
          field: 'governorate',
        },
        {
          label: 'Téléphone',
          field: 'phone1',

        },
        {
          label: 'Etat',
          field: 'status',
          width: '110px',
        },
        {
          label: 'Prix TTC',
          field: 'price_ttc',
          width: '110px',
        },
        {
          label: 'N°retour',
          field: 'return_number',
          width: '110px',
        },
        {
          label: 'Date de création',
          formatFn: this.formatFn,
          field: 'created_at',
          width: '130px',
        },
      ],
      repository: { id: null },
      repositories: [],
      role: '',
      orders: [],
      user: {},
      isLoading: false,
      bl: '',
      awaitingSearch: false,
      supplier1: {
        id: null,
      },
      returnDetails: {},
      showPopup: false,
    }
  },

  created() {
    this.user = storeAuth.state.user
    if (this.level) {
      this.status = Number(this.level)
    }
    if (this.user.role === 'admin') {
      this.getRepositories()
      this.getSuppliers()
    }
    if (this.user.role === 'manager') {
      this.getSuppliers()
    }
    this.getOrders(1)
    this.getGovernorates()
  },

  methods: {
    async openReturnReasonModal(orderId) {
      const response = await axios.get(`/api/orders/${orderId}/return-details/`)
      const returnDetails = response.data
      // Assuming you have a state variable to store the return details
      this.returnDetails = returnDetails
      this.$refs.returnReasonModal.show()
    },
    clearGovernorate() {
      this.governorate = ''
      if (this.supplier1.id != null) {
        this.findBySupplier(1)
      } else {
        this.getOrders(1)
      }
    },
    clearSupplier() {
      this.supplier1 = { id: null }
      if (this.governorate) {
        this.findByGovernorate(1)
      } else {
        this.getOrders(1)
      }
    },
    clearDepot() {
      this.repository = {}
      this.getOrders(1)
    },
    async getGovernorates() {
      const { data } = await axios.get('/api/governorates/')
      this.governorates = data
    },
    async pageNext(currentPage) {
      this.getOrders(currentPage)
    },
    async findById() {
      this.name = ''
      this.phone = ''
      if (this.bl.length >= 5) {
        this.isLoading = true
        const { data } = await axios.get(`/api/orders/search-by-bl/${this.bl}`)
        this.orders = data
        this.pageLength = data.length
        this.isLoading = false
      } else {
        this.getOrders(1)
      }
    },
    // eslint-disable-next-line consistent-return
    isNumber(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = evt || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    async findByName() {
      this.bl = ''
      this.phone = ''
      if (this.name.length >= 4) {
        this.isLoading = true
        const { data } = await axios.get(`/api/orders/search-by-name/${this.name}`)
        this.orders = data
        this.pageLength = data.length
        this.isLoading = false
      } else if (this.name.length === 0) {
        this.getOrders(1)
      }
    },

    async findByPhone() {
      this.count = 1
      this.name = ''
      this.bl = ''
      if (this.phone.length === 8) {
        this.isLoading = true
        const { data } = await axios
          .get(`/api/orders/search-by-phone/${this.phone}`)
        // data.created_at = formatDistance(new Date(data.created_at), new Date(), { addSuffix: true, locale: fr })
        this.orders = data
        this.pageLength = data.length
        this.isLoading = false
      } else if (this.phone.length === 0) {
        this.getOrders(1)
      }
    },
    async findByGovernorate(currentPage) {
      this.isLoading = true
      this.pageLength = 10
      this.name = ''
      this.bl = ''
      this.phone = ''

      const params = {
        status: this.status,
        repository: this.repository.id,
        p: currentPage,
        supplier: this.supplier1.id,
      }
      const { data } = await axios
        .get(`/api/orders/search-by-governorate/${this.governorate.id}`, { params })
      this.orders = []
      this.orders = data.results
      this.count = data.count
      this.isLoading = false
    },

    async findBySupplier(currentPage) {
      this.isLoading = true
      this.pageLength = 10
      this.name = ''
      this.bl = ''
      this.phone = ''

      const params = {
        status: this.status,
        repository: this.repository.id,
        governorate: this.governorate.id,
        p: currentPage,
      }
      const { data } = await axios
        .get(`/api/orders/search-by-supplier/${this.supplier1.id}`, { params })
      this.orders = []
      this.orders = data.results
      this.count = data.count
      this.isLoading = false
    },

    async getRepositories() {
      await axios.get('/api/repositories/').then(response => {
        this.repositories = response.data
      })
    },

    formatFn(value) {
      return formatDistance(new Date(value), new Date(), { addSuffix: true, locale: fr })
    },
    async getSuppliers() {
      await axios.get('api/auth/users/suppliers/').then(response => {
        this.suppliers = response.data
      })
    },
    async getOrders(currentPage) {
      this.pageLength = 10
      this.searchTerm = ''
      if (this.$route.name === 'order-list') {
        this.$router.replace({ name: 'order-list', params: { level: Number(this.status) } })
      }

      try {
        this.isLoading = true
        let params
        if (this.supplier !== null) {
          params = {
            supplier: this.supplier,
            status: this.status,
            governorate: this.governorate.id,
            p: currentPage,
          }
        } else {
          params = {
            supplier: this.supplier1.id,
            repository: this.repository.id,
            status: this.status,
            governorate: this.governorate.id,
            p: currentPage,
          }
        }
        const { data } = await axios.get('/api/orders/list/', {
          params,
        })
        this.count = data.count

        this.orders = data.results
        this.isLoading = false
      } catch (error) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', error.toString())
        }, 1000)
      }
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    deleteOrder(id) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.isLoading = true
        if (result.value) {
          axios
            .delete(`/api/orders/delete/${id}/`)
            .then(() => {
              this.isLoading = false
              this.getOrders()
              setTimeout(() => {
                this.showToast('success', 'top-center', 'colis supprimé avec succés')
              }, 1000)
            })
            .catch(error => {
              this.isLoading = false
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.isLoading = false
          this.$swal({
            title: 'Fermer',
            text: 'colis Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },

  },

}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
